import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Tooltip from '@atoms/Tooltip';

const StyledLabel = styled.label`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    ${theme.typography.titleS};
    color: ${theme.colors.white};
    padding-bottom: ${theme.spacing(1)};
    position: relative;
    min-height: 34px;

    span {
      font-style: normal;
      text-transform: none;
      ${theme.typography.footnote};
      display: inline-block;
      padding-left: ${theme.spacing(0.5)};
    }
  `}
`;

const Input = styled.input`
  ${({ theme }) => css`
    ${theme.typography.footnote};
    position: relative;
    left: 0;
    z-index: 1;
    appearance: none;
    background: transparent;
    color: ${theme.colors.white};
    border: 1px solid rgba(102, 102, 102, 0.4);
    border-radius: ${theme.spacing(2)};
    height: ${theme.spacing(5)};
    width: ${theme.spacing(7)};
    text-align: center;

    ::placeholder {
      color: ${theme.colors.white};
    }

    :focus {
      outline: none;
      border: 1px solid ${theme.colors.white};
      border-radius: ${theme.spacing(2)};
    }

    &[type='number'] {
      -moz-appearance: textfield; /*For FireFox*/

      &::-webkit-inner-spin-button {
        /*For Webkits like Chrome and Safari*/
        -webkit-appearance: none;
        margin: 0;
      }
    }
  `}
`;

const InputForm = React.memo(
  React.forwardRef(
    ({ tooltip, tooltipLeft, unitsLabel, label, ...rest }, ref) => (
      <div style={{ padding: '0 0 32px 0' }}>
        <StyledLabel>
          {label}
          {tooltip && <Tooltip tooltip={tooltip} left={tooltipLeft} />}
        </StyledLabel>
        <Input ref={ref} {...rest} />
        <span> {unitsLabel}</span>
      </div>
    )
  )
);

InputForm.propTypes = {
  unitsLabel: PropTypes.string,
  label: PropTypes.string,
  tooltipLeft: PropTypes.bool,
};

export default InputForm;
