import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';

import PromotionalOverlay from '@organisms/PromotionalOverlay';
import MainLayout from '@layouts/Main';
import Metadata from '@atoms/Metadata';
import Hero, { adapter as heroAdapter } from '@organisms/Hero';
import { getSettings, getPageMeta } from '@utils';

import {
  RelatedCardsCarousel,
  adapter as relatedCardsAdapter,
} from '@organisms/RelatedCards';
import trackEvent from './trackEvent';

function exploreCategoryTribes(tribes, pages) {
  const m = new Map(pages.map((p) => [p.slug, p]));
  return tribes
    .map((t) =>
      relatedCardsAdapter({
        id: t.id,
        title: t.name,
        cards: t.content_page?.map((p) => m.get(p.slug)),
      })
    )
    .filter((t) => t.cards);
}

const CategoryExplorePage = ({ data, pageContext, path }) => {
  const settings = getSettings(
    data.contentfulSettings,
    data.allContentfulLabel,
    pageContext
  );
  const page = data.contentfulContentCategory;
  const pageSeo = getPageMeta(page.seo, settings.brandName);
  const contentPages = data.allContentfulContentPage.nodes;
  const promotionalOverlayProps = settings?.promotionalOverlay;

  useEffect(() => {
    window.tc_vars = {
      country: process.env.GATSBY_MARKET,
      environment: process.env.GATSBY_TRACKING_ENV,
      pageName: pageSeo.title,
      url: `${pageContext.domain}${path}`,
    };

    trackEvent(path);
  }, [pageSeo.title, pageContext.domain, path]);

  return (
    <MainLayout settings={settings}>
      <Metadata path={path} meta={pageSeo} />
      <Hero {...heroAdapter(page.hero)} path={path} pageId={pageContext.id} />
      <StyledRelatedCardsCarousel
        biggerCards
        {...relatedCardsAdapter(page.featuredArticles)}
      />
      {exploreCategoryTribes(page.tribes, contentPages).map((t) => (
        <StyledRelatedCardsCarousel {...t} />
      ))}
      {!!promotionalOverlayProps && (
        <PromotionalOverlay {...promotionalOverlayProps} />
      )}
    </MainLayout>
  );
};

/**
 * GraphQL Page query
 */
export const pageQuery = graphql`
  query getCategoryExplorePageData(
    $nodeLocale: String!
    $id: String!
    $tag: String!
  ) {
    contentfulSettings(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
    ) {
      ...Settings
    }

    allContentfulLabel(
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...Label
      }
    }

    allContentfulContentPage(
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        ...Card
      }
    }

    contentfulContentCategory(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
      contentful_id: { eq: $id }
    ) {
      hero {
        ...Hero
      }
      featuredArticles {
        ...RelatedCards
      }
      tribes {
        id
        name
        content_page {
          slug
        }
      }
    }
  }
`;

CategoryExplorePage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    nodeLocale: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    marketData: PropTypes.object.isRequired,
    domain: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    localizedPageData: PropTypes.object.isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
};

export default CategoryExplorePage;

const StyledRelatedCardsCarousel = styled(RelatedCardsCarousel)`
  ${({ theme }) => css`
    & + & {
      margin-top: ${theme.spacing(8)};

      ${theme.mediaquery.sm(css`
        margin-top: ${theme.spacing(10)};
      `)}
    }
  `}
`;
