import PictureCarousel, {
  adapter as PictureCarouselAdapter,
} from '@molecules/PictureCarousel';
import TextModule, { adapter as TextModuleAdapter } from '@atoms/TextModule';
import {
  RelatedCardsCarousel,
  RelatedCardsAccordion,
  adapter as RelatedCardsAdapter,
  moreByInfluencerAdapter,
} from '@organisms/RelatedCards';
import TwoColumnsText, {
  adapter as TwoColumnsTextAdapter,
} from '@molecules/TwoColumnsText';
import {
  PageComponent as LinkPageComponent,
  adapter as LinkAdapter,
} from '@atoms/Link';
import EditorialPush, {
  adapter as EditorialPushAdapter,
} from '@molecules/EditorialPush';
import Share, { adapter as ShareAdapter } from '@molecules/Share';
import EcommercePush, {
  adapter as EcommercePushAdapter,
} from '@organisms/EcommercePush';
import ImagePush, { adapter as ImagePushAdapter } from '@molecules/ImagePush';
import {
  PageComponent as VideoPushPageComponent,
  adapter as VideoPushAdapter,
} from '@molecules/VideoPush';
import ProductPush, {
  adapter as ProductPushAdapter,
} from '@molecules/ProductPush';
import FeaturedPostCarousel, {
  adapter as FeaturedPostCarouselAdapter,
} from '@molecules/FeaturedPostCarousel';
import Recipe, { adapter as RecipeAdapter } from '@organisms/Recipe';
import QuestionAnswerList, {
  adapter as QuestionAnswerListAdapter,
} from '@organisms/QuestionAnswerList';
import InfluencerProfile, {
  adapter as InfluencerProfileAdapter,
} from '@molecules/InfluencerProfile';
import CodeEmbed, { adapter as CodeEmbedAdapter } from '@atoms/CodeEmbed';
import Explorer, { adapter as ExplorerAdapter } from '@organisms/Explorer';
import ProgramPush, {
  adapter as ProgramPushAdapter,
} from '@molecules/ProgramPush';
import ProgramFunnel, {
  adapter as ProgramFunnelAdapter,
} from '@organisms/ProgramFunnel';
import RecipeSelector, {
  adapter as RecipeSelectorAdapter,
} from '@molecules/RecipeSelector';
import MuscleTool, {
  adapter as MuscleToolAdapter,
} from '@organisms/MuscleTool';
import Calculator, {
  adapter as CalculatorAdapter,
} from '@organisms/Calculator';
import CalculatorPush, {
  adapter as CalculatorPushAdapter,
} from '@molecules/CalculatorPush';
import Disclaimer, {
  adapter as DisclaimerAdapter,
} from '@molecules/Disclaimer';
import CLPCategory, {
  adapter as CLPCategoryAdaptor,
} from '@organisms/CLPCategory';
import ImagePushList, {
  adapter as ImagePushListAdapter,
} from '@molecules/ImagePushList';

export default {
  ContentfulPictureCarousel: {
    Component: PictureCarousel,
    mapper: PictureCarouselAdapter,
  },
  ContentfulTextModule: {
    Component: TextModule,
    mapper: TextModuleAdapter,
  },
  ContentfulRelatedContent: {
    Component: RelatedCardsCarousel,
    mapper: RelatedCardsAdapter,
  },
  ContentfulTwoColumnText: {
    Component: TwoColumnsText,
    mapper: TwoColumnsTextAdapter,
  },
  ContentfulLink: {
    Component: LinkPageComponent,
    mapper: LinkAdapter,
  },
  ContentfulEditorialPush: {
    Component: EditorialPush,
    mapper: EditorialPushAdapter,
  },
  ContentfulShareModule: {
    Component: Share,
    mapper: ShareAdapter,
  },
  ContentfulECommercePush: {
    Component: EcommercePush,
    mapper: EcommercePushAdapter,
  },
  ContentfulImagePush: {
    Component: ImagePush,
    mapper: ImagePushAdapter,
  },
  ContentfulVideo: {
    Component: VideoPushPageComponent,
    mapper: VideoPushAdapter,
  },
  ContentfulProductPush: {
    Component: ProductPush,
    mapper: ProductPushAdapter,
  },
  ContentfulFeaturedPostCarousel: {
    Component: FeaturedPostCarousel,
    mapper: FeaturedPostCarouselAdapter,
  },
  ContentfulRecipeDetails: {
    Component: Recipe,
    mapper: RecipeAdapter,
  },
  ContentfulQuestionAnswerList: {
    Component: QuestionAnswerList,
    mapper: QuestionAnswerListAdapter,
  },
  ContentfulInfluencerProfile: {
    Component: InfluencerProfile,
    mapper: (...args) => ({ influencers: [InfluencerProfileAdapter(...args)] }),
  },
  ContentfulCodeEmbed: {
    Component: CodeEmbed,
    mapper: CodeEmbedAdapter,
  },
  ContentfulExploreModule: {
    Component: Explorer,
    mapper: ExplorerAdapter,
  },
  ContentfulProgramPush: {
    Component: ProgramPush,
    mapper: ProgramPushAdapter,
  },
  ContentfulProgramFunnel: {
    Component: ProgramFunnel,
    mapper: ProgramFunnelAdapter,
  },
  ContentfulRecipeSelector: {
    Component: RecipeSelector,
    mapper: RecipeSelectorAdapter,
  },
  ContentfulMoreByInfluencer: {
    Component: RelatedCardsAccordion,
    mapper: moreByInfluencerAdapter,
  },
  ContentfulMuscleTool: {
    Component: MuscleTool,
    mapper: MuscleToolAdapter,
  },
  ContentfulCalculator: {
    Component: Calculator,
    mapper: CalculatorAdapter,
  },
  ContentfulCalculatorPush: {
    Component: CalculatorPush,
    mapper: CalculatorPushAdapter,
  },
  ContentfulDisclaimer: {
    Component: Disclaimer,
    mapper: DisclaimerAdapter,
  },
  ContentfulConsumptionMomentCategory: {
    Component: CLPCategory,
    mapper: CLPCategoryAdaptor,
  },
  ContentfulImagePushList: {
    Component: ImagePushList,
    mapper: ImagePushListAdapter,
  },
};
