import { getImageFields } from '@utils';
import { IMG_TYPES } from '@atoms/Img';
import { adapter as programPushAdapter } from '@molecules/ProgramPush';
import { adapter as cardAdapter } from '@molecules/Card';
import { adapter as relatedCardsAdapter } from '@organisms/RelatedCards';

// This function makes sure that both arrays have the same length.
// If not, we use the smaller array to avoid errors during render
const getCarousels = (categories, contentCarousels) => {
  let baseArray = categories; // use categories by default

  if (categories.length !== contentCarousels.length) {
    // length is different, let's find out which array to use as a base
    baseArray =
      categories.length > contentCarousels.length
        ? contentCarousels
        : categories;
  }

  return baseArray.map((item, index) => {
    const type = categories[index].name;
    const { id, title, cards } = relatedCardsAdapter(contentCarousels[index]);
    return {
      type,
      id,
      title: title || type,
      cards,
    };
  });
};

export default (
  { tribesTitle, tribes, programPush, categories, contentCarousels },
  { contentPages }
) => ({
  tribesTitle,
  tribes: tribes.map(({ id, filterUrlValue, filterLabel, filterImage }) => ({
    id,
    name: filterLabel,
    urlValue: filterUrlValue,
    image: getImageFields(filterImage, IMG_TYPES.fluid),
  })),
  programsCarousel: {
    id: 'funnel-programs-carousel',
    cards: programPush.map((push) => ({
      ...programPushAdapter(push),
      tribes: push.program.tribes.map((tribe) => tribe.filterUrlValue),
      supportingImages: push.program.supportingImages.map((img) =>
        getImageFields(img, IMG_TYPES.fluid)
      ),
    })),
  },
  carousels: getCarousels(categories, contentCarousels),
  allCards: contentPages.map((card) => cardAdapter(card)),
});
