import { adapter as relatedCardsAdapter } from '@organisms/RelatedCards';
import { adapter as programCardAdapter } from '@molecules/ProgramCard';
import { adapter as cardAdapter } from '@molecules/Card';
import { adapter as calculatorPushAdapter } from '@molecules/CalculatorPush';

const getFilters = (cards, key) =>
  cards
    .slice(0, cards.length)
    .filter(
      (item, index, array) =>
        array.findIndex((v) => v[key] === item[key]) === index
    )
    .map((item) => ({
      key: item[key],
      label: item[key],
    }));

export default (
  {
    latestTitle,
    featuredArticlesTitle,
    featuredArticles,
    featuredProgramsTitle,
    featuredPrograms,
    filtersTitle,
    showMoreLabel,
    showLessLabel,
    calculatorPush,
  },
  contentPages
) => {
  const cards = contentPages.map((page) => cardAdapter(page));
  const latestCards = cards.slice(0, 5);

  return {
    latestArticles: {
      id: 'latest-articles',
      title: latestTitle,
      cards: latestCards,
    },
    featuredArticlesTitle,
    featuredArticles: featuredArticles.map((item) => relatedCardsAdapter(item)),
    featuredPrograms: {
      id: 'featured-program',
      title: featuredProgramsTitle,
      cards: featuredPrograms.map((item) => programCardAdapter(item)),
    },
    filterableCards: {
      title: filtersTitle,
      contentTypes: getFilters(cards, 'type'),
      categoryTypes: getFilters(cards, 'categoryType'),
      cards,
      showMoreLabel,
      showLessLabel,
    },
    calculatorPush: calculatorPush && calculatorPushAdapter(calculatorPush),
  };
};
