import React, { useContext, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import styled, { css } from 'styled-components';

import { SettingsContext } from '@stores/Settings';
import { RelatedCardsCarousel } from '@organisms/RelatedCards';
import ProgramsCarousel from '@organisms/ProgramsCarousel';
import CardsAccordion from '@organisms/CardsAccordion';
import { CardPortrait } from '@molecules/Card';
import ChipSelector from '@molecules/ChipSelector';
import CalculatorPush from '@molecules/CalculatorPush/CalculatorPush';

const ExploreContainer = styled.div``;

const FeaturedArticlesTitle = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleXL};
    padding: 0 var(--outer-gap);
    margin-top: ${theme.spacing(8)};

    ${theme.mediaquery.sm(css`
      padding: 0 calc(var(--outer-gap) + var(--col) * 3);
      margin-top: ${theme.spacing(10)};
    `)}
  `}
`;

const FeaturedArticlesCarousel = styled(RelatedCardsCarousel)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(8)};

    ${theme.mediaquery.sm(css`
      margin-top: ${theme.spacing(10)};
    `)}
  `}
`;

const StyledProgramsCarousel = styled(ProgramsCarousel)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(15)};
  `}
`;

const FiltersContainer = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(8)};
    margin-bottom: ${theme.spacing(3)};
    background: ${theme.colors.white};
    padding: ${theme.spacing(5)} var(--outer-gap);

    ${theme.mediaquery.sm(css`
      margin-top: ${theme.spacing(10)};
      padding: ${theme.spacing(5)} calc(var(--outer-gap) + var(--col) * 3);
      margin-bottom: ${theme.spacing(5)};
    `)}
  `}
`;

const FiltersTitle = styled.p`
  ${({ theme }) => css`
    ${theme.typography.titleM};
    color: ${theme.colors.black};
  `}
`;

const StyledChipSelector = styled(ChipSelector)`
  ${({ theme }) => css`
    & > div {
      justify-content: flex-start;

      & > div {
        border-radius: ${theme.spacing(2)};
        padding: ${theme.spacing(1.5)} ${theme.spacing(2)};
        ${theme.typography.bodyM};

        ${theme.mediaquery.sm(css`
          ${theme.typography.bodyL};
        `)}
      }
    }
    margin-top: ${theme.spacing(2)};
  `}
`;

const Explore = ({
  latestArticles,
  featuredArticlesTitle,
  featuredArticles,
  featuredPrograms,
  filterableCards,
  calculatorPush,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedCategoryFilters, setSelectedCategoryFilters] = useState([]);
  const [selectedContentTypeFilters, setSelectedContentTypeFilters] = useState(
    []
  );

  const { translations } = useContext(SettingsContext);
  const {
    editorialLabel,
    influencerLabel,
    recipeLabel,
    videoLabel,
  } = translations;

  const contentTypeLabelMap = useMemo(
    () => ({
      editorial: editorialLabel,
      recipe: recipeLabel,
      influencer: influencerLabel,
      video: videoLabel,
    }),
    [editorialLabel, recipeLabel, influencerLabel, videoLabel]
  );

  const contentTypeFilters = useMemo(
    () =>
      filterableCards.contentTypes.map((item) => ({
        ...item,
        label: contentTypeLabelMap[item.key],
      })),
    [contentTypeLabelMap, filterableCards.contentTypes]
  );

  const visibleCards = useMemo(
    () =>
      filterableCards.cards.filter((card) => {
        if (selectedContentTypeFilters.length) {
          if (!selectedContentTypeFilters.includes(card.type)) {
            return false;
          }
        }

        if (selectedCategoryFilters.length) {
          if (!selectedCategoryFilters.includes(card.categoryType)) {
            return false;
          }
        }

        return true;
      }),
    [filterableCards.cards, selectedCategoryFilters, selectedContentTypeFilters]
  );

  // handles change of filters
  const updateFilters = (newFilters, type) => {
    if (type === 'category') {
      setSelectedCategoryFilters(newFilters);
    } else if (type === 'content-type') {
      setSelectedContentTypeFilters(newFilters);
    }
  };

  const getSelectedFilters = (urlParams) => {
    const params = urlParams.replace('?', '');
    params.split('&').forEach((filter) => {
      const [filterType, filterValue] = filter.split('=');
      const filterValueArray = filterValue.split(',');
      switch (filterType) {
        case 'ct':
          setSelectedContentTypeFilters(filterValueArray);
          break;
        case 'c':
          setSelectedCategoryFilters(filterValueArray);
          break;
        default:
          break;
      }
    });
  };

  useEffect(() => {
    if (isVisible) {
      const categoryParam =
        selectedCategoryFilters.length > 0
          ? `c=${selectedCategoryFilters.join(',')}`
          : null;
      const contentTypeParam =
        selectedContentTypeFilters.length > 0
          ? `ct=${selectedContentTypeFilters.join(',')}`
          : null;
      const urlParams = `?${categoryParam || ''}${
        categoryParam && contentTypeParam ? '&' : ''
      }${contentTypeParam || ''}`;
      navigate(urlParams, {
        replace: true,
        state: { keepScrollPosition: true },
      });
    }
  }, [selectedCategoryFilters, selectedContentTypeFilters, isVisible]);

  useEffect(() => {
    const urlParams = window.location.search;
    if (urlParams && urlParams !== '') {
      getSelectedFilters(urlParams);
    }
    setTimeout(() => {
      setIsVisible(true);
    }, 300);
  }, []);

  return (
    <ExploreContainer>
      <RelatedCardsCarousel {...latestArticles} biggerCards />
      <FeaturedArticlesTitle>{featuredArticlesTitle}</FeaturedArticlesTitle>
      <div>
        {featuredArticles.map((item) => (
          <FeaturedArticlesCarousel key={item.id} {...item} />
        ))}
      </div>
      <StyledProgramsCarousel {...featuredPrograms} />
      <FiltersContainer>
        <FiltersTitle>{filterableCards.title}</FiltersTitle>
        <StyledChipSelector
          items={filterableCards.categoryTypes}
          onChange={(newFilters) => updateFilters(newFilters, 'category')}
          selectedChips={selectedCategoryFilters}
          lightTheme
        />
        <StyledChipSelector
          items={contentTypeFilters}
          onChange={(newFilters) => updateFilters(newFilters, 'content-type')}
          selectedChips={selectedContentTypeFilters}
          lightTheme
        />
      </FiltersContainer>
      {calculatorPush && <CalculatorPush {...calculatorPush} />}
      <CardsAccordion
        cards={visibleCards}
        hiddenLabel={filterableCards.showMoreLabel}
        visibleLabel={filterableCards.showLessLabel}
        renderCard={(card) => <CardPortrait {...card} />}
        cardsShown={9}
        maxHeight="9999rem"
      />
    </ExploreContainer>
  );
};

Explore.propTypes = {
  latestArticles: PropTypes.shape(RelatedCardsCarousel.propTypes).isRequired,
  featuredArticlesTitle: PropTypes.string.isRequired,
  featuredArticles: PropTypes.arrayOf(
    PropTypes.shape(RelatedCardsCarousel.propTypes)
  ).isRequired,
  featuredPrograms: PropTypes.shape(ProgramsCarousel.propTypes).isRequired,
  filterableCards: PropTypes.shape({
    title: PropTypes.string.isRequired,
    contentTypes: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
      })
    ).isRequired,
    categoryTypes: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
      })
    ).isRequired,
    cards: PropTypes.arrayOf(PropTypes.shape(CardPortrait.propTypes))
      .isRequired,
    showMoreLabel: PropTypes.string.isRequired,
    showLessLabel: PropTypes.string.isRequired,
  }),
  calculatorPush: PropTypes.object,
};

export default Explore;
