import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img, { IMG_FITS, IMG_TYPES } from '@atoms/Img';
import { buttonStyle } from '@atoms/Button';
import Link, { LINK_TYPES } from '@atoms/Link';
import InfluencerTagline from '@molecules/InfluencerTagline';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import PlusOneButton from '@atoms/PlusOneButton';

import { rgba } from '@utils';

const ANIMATION_DURATION = 580;
const MODE = 'in-out';

const CardContainer = styled(Link)`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  ${({ theme }) => theme.mediaquery.sm(css``)}
  ${({ theme }) =>
    theme.mediaquery.lg(css`
      align-items: flex-start;
      flex-flow: row nowrap;
      align-items: center;
    `)}
`;

const TopContainer = styled.div`
  width: 100%;
  grid-column: 1 / -1;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 40%;
    background: ${({ theme }) => `linear-gradient(
      0deg,
      ${rgba(theme.colors.black, 0)},
      ${theme.colors.black}
    )`};
    opacity: 0.6;
    z-index: 2;
  }

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      width: calc(var(--col) * 14 - var(--inner-gap));
      grid-row: 1;
    `)}

    ${theme.mediaquery.md(css`
      width: calc(var(--col) * 12 - var(--inner-gap));
    `)}

    ${theme.mediaquery.lg(css`
      width: calc(var(--col) * 10 - var(--inner-gap));
    `)}
  `}
`;

const InfluencersList = styled.ul`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.spacing(2)};
    left: ${theme.spacing(2)};
    right: 0;
    z-index: 2;

    ${theme.mediaquery.md(css`
      top: ${theme.spacing(3)};
      left: ${theme.spacing(3)};
    `)}
  `}
`;

const InfluencerItem = styled.li`
  ${({ theme }) => css`
    &:not(:first-of-type) {
      margin-top: ${theme.spacing(1)};
    }
  `}
`;

const ImgContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: ${(447 / 337) * 100}%;
  background: ${({ theme }) => theme.colors.pressedGray};
  position: relative;

  ${({ isProgramsCarousel, isActive, theme }) =>
    isProgramsCarousel &&
    !isActive &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          0.25turn,
          ${rgba(theme.colors.black, 0.8)} 0%,
          ${rgba(theme.colors.black, 0)} 100%
        );
        z-index: 10;
      }
    `}

  ${({ theme }) =>
    theme.mediaquery.sm(css`
      padding-bottom: ${(743 / 562) * 100}%;
    `)}
`;

const StyledImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;

  overflow: visible !important;

  &.enter {
    opacity: 0;
  }

  &.enter-active {
    opacity: 1;
    transition: opacity ${ANIMATION_DURATION}ms;
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
    transition: opacity ${ANIMATION_DURATION}ms;
  }
`;

const Content = styled.div`
  ${({ theme, hasMarginFailsafe, isProgramsCarousel, isActive }) => css`
    grid-column: 1 / -1;

    transition: opacity 0.5s ease-in-out;
    opacity: ${isProgramsCarousel && !isActive ? 0 : 1};

    ${theme.mediaquery.sm(css`
      width: calc(var(--col) * 9 - var(--inner-gap));
      grid-row: 1;
      z-index: 2;
      margin-top: 0;
    `)}

    ${theme.mediaquery.md(css`
      width: calc(var(--col) * 14 - var(--inner-gap));
      margin-top: ${hasMarginFailsafe ? theme.spacing(6) : '0'};
    `)}

    ${theme.mediaquery.lg(css`
      grid-column: none;
      padding-left: calc(var(--col) * 1);
      width: calc(var(--col) * 7 - var(--inner-gap));
      margin-top: 0;
    `)}
  `}
`;

const Headline = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleL};
    color: ${theme.colors.white};

    ${theme.mediaquery.md(css`
      ${theme.typography.titleXL};
    `)}
  `}
`;

const SubHeadline = styled.p`
  ${({ theme }) => css`
    ${theme.typography.bodyL};
    color: ${theme.colors.white};
    margin-top: ${theme.spacing(2)};

    ${theme.mediaquery.md(css`
      ${theme.typography.titleM};
      text-transform: none !important;
      letter-spacing: 0 !important;
    `)}
  `}
`;

const Button = styled.div`
  ${({ theme }) => css`
    ${buttonStyle(theme, 'primary')};
    margin-top: ${theme.spacing(4)};
    display: inline-block;
  `}
`;

const ProgramCard = ({
  slug,
  image,
  influencers,
  headline,
  subHeadline,
  ctaLabel,
  isProgramsCarousel,
  isActive,
}) => (
  <CardContainer
    title={headline}
    url={slug}
    state={image}
    type={LINK_TYPES.internal}
  >
    <TopContainer>
      <InfluencersList>
        {influencers.map((influencer) => (
          <InfluencerItem key={influencer.name}>
            <InfluencerTagline {...influencer} />
          </InfluencerItem>
        ))}
      </InfluencersList>
      <ImgContainer isProgramsCarousel={isProgramsCarousel} isActive={isActive}>
        <SwitchTransition mode={MODE}>
          <CSSTransition timeout={ANIMATION_DURATION} key={image.src}>
            <StyledImg
              type={IMG_TYPES.fluid}
              fit={IMG_FITS.cover}
              small={image}
              alt={headline}
            />
          </CSSTransition>
        </SwitchTransition>
      </ImgContainer>
      <PlusOneButton favoriteId={slug} slug={slug} />
    </TopContainer>
    <Content
      hasMarginFailsafe={influencers.length === 3}
      isProgramsCarousel={isProgramsCarousel}
      isActive={isActive}
    >
      <Headline>{headline}</Headline>
      <SubHeadline>{subHeadline}</SubHeadline>
      <Button>{ctaLabel}</Button>
    </Content>
  </CardContainer>
);

ProgramCard.propTypes = {
  slug: PropTypes.string.isRequired,
  image: PropTypes.object,
  state: PropTypes.object,
  influencers: PropTypes.arrayOf(PropTypes.shape(InfluencerTagline.propTypes))
    .isRequired,
  headline: PropTypes.string.isRequired,
  subHeadline: PropTypes.string.isRequired,
  ctaLabel: PropTypes.string.isRequired,
  isProgramsCarousel: PropTypes.bool,
  isActive: PropTypes.bool,
};

export default ProgramCard;
