import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import RetailerButton, { RETAILER_BUTTON_WIDTH } from '@atoms/RetailerButton';

const List = styled.ul`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(0, ${RETAILER_BUTTON_WIDTH}px)
    );
    grid-gap: ${theme.spacing(3)};
    padding: 0 var(--outer-gap);
    justify-content: center;

    ${theme.mediaquery.sm(css`
      padding: 0;
      max-width: calc(${RETAILER_BUTTON_WIDTH}px * 4 + ${theme.spacing(3)} * 3);
      margin-left: auto;
      margin-right: auto;
    `)}
  `}
`;

const ListItem = styled.li`
  grid-column: span 1;
`;

const RetailersList = ({ retailers, className }) => (
  <List className={className}>
    {retailers.map(({ name, logo, url }) => (
      <ListItem key={name}>
        <RetailerButton name={name} logo={logo} url={url} />
      </ListItem>
    ))}
  </List>
);

RetailersList.propTypes = {
  className: PropTypes.string,
  retailers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      logo: PropTypes.object.isRequired,
    })
  ).isRequired,
};

export default RetailersList;
