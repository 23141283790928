import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import EssentialBcaas from '@icons/essential-bcaas.svg';
import NoAddedSugar from '@icons/no-added-sugar.svg';
import LowSugar from '@icons/low-sugar.svg';
import AminoAcids from '@icons/9-essential-aminoacids.svg';
import NoColorants from '@icons/no-colorantes-or-conservatives.svg';
import LowFat from '@icons/low-fat.svg';
import ZeroFat from '@icons/0-fat.svg';
import FreshFromFridge from '@icons/4hours-fresh-from-fridge.svg';
import LactoseFree from '@icons/lactose-free.svg';
import WheyProtein from '@icons/whey-protein.svg';
import VitaminD from '@icons/vitamin-d.svg';
import ZeroMG from '@icons/0mat.svg';
import FiveBCAA from '@icons/5bcaas.svg';
import Nutriscore from '@icons/nutriscore.svg';
import Recycle from '@icons/recycle.svg';
import RichInProteins from '@icons/rich-in-proteins.svg';
import ZeroPercent from '@icons/zero-percent.svg';
import vitamineC from '@icons/vitamine-c.svg';
import vitamineB9 from '@icons/vitamine-b9.svg';

export const WHATSINSIDE_TYPES = Object.freeze({
  essentialbcaas: 'Essential BCAAs',
  essentialaminoacids: '9 Essential Amino Acids',
  wheyprotein: 'Whey Protein',
  lowsugar: 'Low Sugar',
  noaddedsugar: 'No Added Sugar',
  nocolorantsorconservatives: 'No Colorantes or Conservatives',
  lowfat: 'Low Fat',
  zerofat: '0% Fat',
  freshfromfridge: '4 Hours fresh from Fridge',
  lactosefree: 'Lactose Free',
  vitamind: 'Vitamin D',
  zeromg: '0% MG',
  bcaa: '5 BCAA',
  nutriscore: 'Nutriscore',
  recycle: 'Recycle',
  richinprotein: 'Rich in Protein',
  zeropercent: 'Zero Percent',
  vitamineC: 'Vitamine C',
  vitamineB9: 'Vitamine B9',
});

const whatsInsideIcons = Object.freeze({
  [WHATSINSIDE_TYPES.essentialbcaas]: EssentialBcaas,
  [WHATSINSIDE_TYPES.noaddedsugar]: NoAddedSugar,
  [WHATSINSIDE_TYPES.essentialaminoacids]: AminoAcids,
  [WHATSINSIDE_TYPES.wheyprotein]: WheyProtein,
  [WHATSINSIDE_TYPES.lowsugar]: LowSugar,
  [WHATSINSIDE_TYPES.nocolorantsorconservatives]: NoColorants,
  [WHATSINSIDE_TYPES.lowfat]: LowFat,
  [WHATSINSIDE_TYPES.zerofat]: ZeroFat,
  [WHATSINSIDE_TYPES.freshfromfridge]: FreshFromFridge,
  [WHATSINSIDE_TYPES.lactosefree]: LactoseFree,
  [WHATSINSIDE_TYPES.vitamind]: VitaminD,
  [WHATSINSIDE_TYPES.zeromg]: ZeroMG,
  [WHATSINSIDE_TYPES.bcaa]: FiveBCAA,
  [WHATSINSIDE_TYPES.nutriscore]: Nutriscore,
  [WHATSINSIDE_TYPES.recycle]: Recycle,
  [WHATSINSIDE_TYPES.richinprotein]: RichInProteins,
  [WHATSINSIDE_TYPES.zeropercent]: ZeroPercent,
  [WHATSINSIDE_TYPES.vitamineC]: vitamineC,
  [WHATSINSIDE_TYPES.vitamineB9]: vitamineB9,
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconContainer = styled.div`
  ${({ theme }) => css`
    width: 100px;
    height: 100px;

    svg {
      /* To show the correct product color as the indicator product, you first need to make sure that any
      parent of this component defines the --product-color variable. */
      fill: var(--product-color, ${theme.colors.white});
    }
  `}
`;

const Label = styled.p`
  ${({ theme }) => css`
    ${theme.typography.titleS};
    color: ${theme.colors.white};
    margin-top: ${theme.spacing(3)};
    text-align: center;
  `}
`;

const WhatsInside = ({ type, label }) => {
  const Icon = whatsInsideIcons[type];

  return (
    <Container>
      <IconContainer>
        <Icon />
      </IconContainer>
      <Label>{label}</Label>
    </Container>
  );
};

WhatsInside.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default WhatsInside;
